import { createAsyncThunk,createSlice} from "@reduxjs/toolkit";
import { Api1, Api2 } from "../api/Api";




  
  export const getAllSingleEmailVerificationSlice=createAsyncThunk("/single/getAll/EmailVerifcation",async({data,url,method},{ rejectWithValue })=>{
      try{
          const response = await Api1(url,method,data);
          return response.data;
  
      }
      catch(error){
          throw rejectWithValue(error.message);
  
  
      }
  })
  
  // Define the initial state
  const initialState = {
    loading:false,
    data:null,
    errror:{
      message:null,
      status:null
    }
  };
  
  // Create the slice
  const singleEmailVerification = createSlice({
    name: 'singleEmailVerification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getAllSingleEmailVerificationSlice.pending, (state) => {
          state.loading=true
          state.data=null;
        })
        .addCase(getAllSingleEmailVerificationSlice.fulfilled, (state, action) => {
          state.loading=false
          state.data = action.payload;
        })
        .addCase(getAllSingleEmailVerificationSlice.rejected, (state, action) => {
          state.loading=false
          state.data=null;
          state.errror.status=true;
          state.errror.message=action.payload;
        });
    }
  });
  
  // Export the reducer to be included in the store
  export default singleEmailVerification.reducer;
  