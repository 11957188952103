import AlertProvider from "../contextApi/AlertContextApi";
import { useState,useContext } from "react";

const AlertContextProvider=({children})=>{
    const [alert, setAlert] = useState(false);
    const [message,setMessage]=useState("");
    const [alertStatus,setAlertStatus]=useState("");

    return (
        <AlertProvider.Provider value={{
            alert,
            setAlert,
            message,
            setMessage,
            setAlertStatus,
            alertStatus

        }}>
            {children}
        </AlertProvider.Provider>
    )
}

const useAlert=()=>{
    const context = useContext(AlertProvider);
    if (context === undefined) {
        console.log("SOME THING WENT WORNG");
    }
    return context;

}

export {useAlert};

export default AlertContextProvider;