import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { Provider } from 'react-redux';
import { ThemeProvider } from "@material-tailwind/react";
import { PaginationProvider } from "./components/dashboard/RightSide/Pagination.jsx";
import Store from "./Store.js";
import ProtectedRoutes from './app/ProtectedRoute.jsx';
import AdminRoutes from './auth/routes/AdminRoute.jsx';
import SuspenseLoading from "./pages/SuspenseLoading.jsx"
import LazyLoadTable from "./custom-component/ServerSideTable.jsx"
import AlertContextProvider, { useAlert } from './custom-hooks/useAlert.jsx'


const App = React.lazy(() => import('./App.jsx'));
const ErrorPage = React.lazy(() => import("./pages/ErrorPage.jsx"));
const SignIn = React.lazy(() => import("./components/signin/Sigin.jsx"));
const SignUp = React.lazy(() => import("./components/signup/Signup.jsx"));
const ResetPassword = React.lazy(() => import("./components/signin/ResetPassword.jsx"));
const VerifyResetPassword = React.lazy(() => import("./components/signin/VerifyResetPassword.jsx"));
const Dashboard = React.lazy(() => import("./components/dashboard/Dashboard.jsx"));
const BulkSearch = React.lazy(() => import("./components/dashboard/EmailFinder/BulkSearch.jsx"));
const SingleSearch = React.lazy(() => import("./components/dashboard/EmailFinder/SingleSearchPage.jsx"));
const BulkVerificationPage = React.lazy(() => import('./components/dashboard/EmailVerification/Bulk/BulkVerificationPage.jsx'));
const SingleVerificationPage = React.lazy(() => import('./components/dashboard/EmailVerification/Single/SingleVerificationPage.jsx'));
const PricingPage = React.lazy(() => import('./components/payment/PricingPage.jsx'));
const ProfilePage = React.lazy(() => import('./components/dashboard/Profile/ProfilePage.jsx'));
const FileById = React.lazy(() => import('./components/dashboard/File/FileById.jsx'));
const EmailFind = React.lazy(() => import("./components/dashboard/File/EmailFind.jsx"));
const EmailFindAndVerifyFile = React.lazy(() => import('./components/dashboard/File/EmailFindAndVerify.jsx'));
const SessionTimeout = React.lazy(() => import("./pages/SessionTimeout.jsx"));
const EmailVerify = React.lazy(() => import("./pages/EmailVerify.jsx"));
const RequestEmailVerification = React.lazy(() => import("./pages/RequestEmailVerification.jsx"));
const EmailInbox = React.lazy(() => import("./pages/Email_Inbox.jsx"));
const FileVerifyById = React.lazy(() => import('./components/dashboard/File/FileVerifyById.jsx'));
const Successpage = React.lazy(() => import('./components/payment/Page/Successpage.jsx'));
const UnauthorizedPage = React.lazy(() => import('./pages/UnauthorizedPage.jsx'));
const AdminLayout = React.lazy(() => import('./AdminLayout.jsx'));
const AboutPage=React.lazy(()=>import("./pages/About.jsx"));
const HelpPage=React.lazy(()=>import("./pages/HelpPage.jsx"));


function MyApp()
{
  const navigate=useNavigate();
  useEffect(()=>{
navigate("/signin")

  },[])
  return <>
  Redirecting...
  </>
}


const router = createBrowserRouter([
  {
    path: "/",
    errorElement: (
      <Suspense fallback={<SuspenseLoading/>}>
        <ErrorPage />
      </Suspense>
    ),
    element: (
      <Suspense fallback={<SuspenseLoading/>}>
        <App />
      </Suspense>
    ),
    children: [
    
      
     
   
      {
        path: "/",
        element: (
          <Suspense fallback={<SuspenseLoading/>}>
            <>
            <MyApp/>
            </>
          </Suspense>
        )
      },
      { 
        path:"/about",
        element:(
          <Suspense fallback={<SuspenseLoading/>}>
          <>
          <AboutPage/>
          </>
        </Suspense>

        )
      },
      {
        path:"/help",
        element:(
          <Suspense fallback={<SuspenseLoading/>}>
          <>
          <HelpPage/>
          </>
        </Suspense>

        )
        
      },

      {
        path: "/emailsearch/bulk",
        element: (
          <ProtectedRoutes allowedRoles={["user"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <BulkSearch />
            </Suspense>
          </ProtectedRoutes>
        )
      },
      {
        path: "/emailsearch/single",
        element: (
          <ProtectedRoutes allowedRoles={["user"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <SingleSearch />
            </Suspense>
          </ProtectedRoutes>
        )
      },
      {
        path: "/emailVerification/bulk",
        element: (
          <ProtectedRoutes allowedRoles={["user"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <BulkVerificationPage />
            </Suspense>
          </ProtectedRoutes>
        )
      },
      {
        path: "/emailVerification/single",
        element: (
          <ProtectedRoutes allowedRoles={["user"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <SingleVerificationPage />
            </Suspense>
          </ProtectedRoutes>
        )
      },
      {
        path: "/pricing",
        element: (
          <ProtectedRoutes allowedRoles={["user"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <PricingPage />
            </Suspense>
          </ProtectedRoutes>
        )
      },
      {
        path: "/profile",
        element: (
          <ProtectedRoutes allowedRoles={["user"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <ProfilePage />
            </Suspense>
          </ProtectedRoutes>
        )
      },
      {
        path: "/file/:id",
        element: (
          <ProtectedRoutes allowedRoles={["user"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <FileById />
            </Suspense>
          </ProtectedRoutes>
        )
      },
      {
        path: "/EmailSearch/find/file/:id",
        element: (
          <ProtectedRoutes allowedRoles={["user"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <EmailFind />
            </Suspense>
          </ProtectedRoutes>
        )
      },
      {
        path: "/EmailSearch/findAndVerify/file/:id",
        element: (
          <ProtectedRoutes allowedRoles={["user"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <EmailFindAndVerifyFile />
            </Suspense>
          </ProtectedRoutes>
        )
      },
      
    
      {
        path: "/request/emailVerify",
        element: (
          <Suspense fallback={<SuspenseLoading/>}>
            <RequestEmailVerification />
          </Suspense>
        )
      },
    
      {
        path:"/example",
        element:(
            <LazyLoadTable/>

        )
      },
      {
        path: "/file/verify/:id",
        element: (
          <ProtectedRoutes allowedRoles={["user"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <FileVerifyById />
            </Suspense>
          </ProtectedRoutes>
        )
      },
   
     
    ]
  },
  {
    path: "/payment/success",
    element: (
      <Suspense fallback={<SuspenseLoading/>}>
        <Successpage />
      </Suspense>
    )
  },

  {
    path: "/resetpassword/verify/:id",
    element: (
      <Suspense fallback={<SuspenseLoading/>}>
        <VerifyResetPassword />
      </Suspense>
    )
  },
  {
    path: "/emailInbox",
    element: (
      <Suspense fallback={<SuspenseLoading/>}>
        <EmailInbox />
      </Suspense>
    )
  },
  {
    path: "/signin",
    element: (
      <Suspense fallback={<SuspenseLoading/>}>
        <SignIn />
      </Suspense>
    )
  },

  {
    path: "/signup",
    element: (
      <Suspense fallback={<SuspenseLoading/>}>
        <SignUp />
      </Suspense>
    )
  },
  {
    path: "/resetPassword",
    element: (
      <Suspense fallback={<SuspenseLoading/>}>
        <ResetPassword />
      </Suspense>
    )
  },
  {
    path: "/admin",
    errorElement: <ErrorPage />,
    element: (
      <ProtectedRoutes allowedRoles={["admin"]}>
      <Suspense fallback={<SuspenseLoading/>}>
      <AdminLayout />
        </Suspense>
      </ProtectedRoutes>
    ),
    children: [
      ...AdminRoutes
    ],
  },
  {
    path: "/session-timeout",
    element: (
      <Suspense fallback={<SuspenseLoading/>}>
        <SessionTimeout />
      </Suspense>
    )
  },
  {
    path: "/emailVerify/:id",
    element: (
      <Suspense fallback={<SuspenseLoading/>}>
        <EmailVerify />
      </Suspense>
    )
  },
  {
    path: "/unauthorized",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <UnauthorizedPage />
      </Suspense>
    )
  },
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <ThemeProvider>
    <Provider store={Store}>
    <AlertContextProvider>

      <PaginationProvider>
        <RouterProvider router={router} />
      </PaginationProvider>
      </AlertContextProvider>

    </Provider>

  </ThemeProvider>
);
