import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./features/slice/userSlice";
import EmailVerifierReducer from "./features/slice/emailVerifier";
import emailVerifier from "./features/slice/emailVerifier";
import singleEmailSearch from "./features/slice/singleEmailSearch";
import fileSlice from "./features/slice/fileSlice";
import emailVerification from "./features/slice/emailVerification";
import singleEmailVerification from "./features/slice/singleEmailVerification";
import adminUserReducer from "./features/slice/admin/admin.userSlice";


const Store=configureStore({
    reducer:{
       auth :AuthReducer,
       emailVerifier:EmailVerifierReducer,
       file:fileSlice,
       singleEmailSearch:singleEmailSearch,
       emailVerification:emailVerification,
       singleEmailVerification:singleEmailVerification,
       adminUser:adminUserReducer
        
    }
})

export default Store;