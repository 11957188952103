import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Api1, Api2 } from "../../api/Api";








// Async thunk getAllUserSlice
export const getAllUserSlice = createAsyncThunk(
  'admin/getAll/users',
  async (data, thunkAPI) => {
    try {
        const {method,url}=data;
        
      const response = await Api2(url,method); // Replace with actual API call
      // Remove token from localStorage or sessionStorage here if needed
      return response;
    } catch (error) {
      throw thunkAPI.rejectWithValue(error.message);
    }
  }
);














// Auth slice with initial state and reducers
const adminUserSlice = createSlice({
  name: 'adminUser',
  initialState: {
   userData:{
    loading:false,
    data:null,
    error:{
        status:false,
        message:""
    }
   }

   
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserSlice.pending, (state) => {
        state.loading = true;
        state.data=null;
      })
      .addCase(getAllUserSlice.fulfilled, (state, action) => {
        state.loading = false;
        state.userData.data = action.payload.data.data;
      })
      .addCase(getAllUserSlice.rejected, (state, {payload}) => {
        state.loading = false;
        state.error.status = false;
        state.error.message=payload?.response?.data || payload?.message || "Something went wront in userSlice of admin"
      })
   

  },
});


export default adminUserSlice.reducer;