import Axios from "../../utils/Proxy";



export const Api1 = (url, method, data) => {

  


    return new Promise(async (resolve, reject) => {
        try {
            // Axios request configuration
            const api1 = await Axios({
                url, // URL passed as argument
                method, // HTTP method passed as argument
                data, // Request payload
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'withCredentials': true // Include credentials (cookies)


                },
            });

            // Check for successful response
            if (api1.status >= 200 && api1.status < 300) {
                resolve(api1);  // Resolve the promise with the response
            } else {
                throw new Error(`Error: ${api1.status} - ${api1.statusText}`);
            }
        } catch (error) {
            console.error("API Error:", error);  // Log the full error for debugging          
            reject(error);  // Reject the promise with the error
        }
    });
};

export const Api2 = (url, method) => {

    return new Promise(async (resolve, reject) => {
        try {

            const api1 = await Axios({

                url: url,
                method: method,
                headers: {
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'withCredentials': true // Include credentials (cookies)

                  }


            })


            if (api1.status >= 200 && api1.status < 300) {
                return resolve(api1);
            }
            throw new Error(api1);



        }

        catch (e) {
          
            return reject(e);
        }
    })

}

export const Api3 = ( url, method, data, responseType = 'json', customHeaders = {}) => {
    return new Promise(async (resolve, reject) => {
        try {
          
            

            // Determine if we need special headers for FormData
            const isFormData = data instanceof FormData;
       

            const headers = {
                'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': '0',
                    'withCredentials': true ,

                  
                ...customHeaders, // Add any custom headers passed
            };

            const apiResponse = await Axios({
                url,
                method,
                data: isFormData ? data : { ...data }, // Send FormData or JSON appropriately
                responseType, // Default responseType is 'json', but can be overridden
                headers,
            });


            if (apiResponse.status >= 200 && apiResponse.status < 300) {
                return resolve(apiResponse);
            } else {
                throw new Error(apiResponse);
            }
        } catch (e) {
            console.error("API error:", e);

           

            return reject(e);
        }
    });
};

export const Api4 = ({url, method,data}) => {
    return new Promise(async (resolve, reject) => {
        try {



            const api1 = await Axios({

                url: url,
                method: method,
                data: {...data},
                responseType: 'blob', // Ensuring the response is of type 'blob'
                headers: { 
                    'Content-Type': 'application/json', // Set your content type if needed
                    // Add any other headers you need
                    withCredentials: true // Include credentials (cookies)

                }

            })
           


            if (api1.status >= 200 && api1.status < 300) {
                return resolve(api1);
            }
            throw new Error(api1);



        }

        catch (e) {
            if (e?.response?.data?.message == "Request failed with status code 401") {
                localStorage.clear();
            }
            return reject(e);
        }
    })

}
