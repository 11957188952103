import React from 'react';
import { Spinner } from "@material-tailwind/react";

const VivaSalesSkeleton = () => {
  return (
    <div 
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        height: '90vh', // Set height to 100vh
        backgroundColor: '#f0f0f0', 
        borderRadius: '8px', 
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)', 
        position: 'relative' // Position relative for possible absolute positioning of other elements
      }}
    >
      {/* Spinner centered in the middle with primary color */}
    </div>
  );
};

export default VivaSalesSkeleton;
