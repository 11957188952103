import React, { useEffect } from 'react';
import { Navigate, useLocation } from "react-router-dom";
import axios from '../utils/Proxy';
import setting from "../../setting.json";

function ProtectedRoutes({ children, allowedRoles }) {
  const location = useLocation();
  const isLogin = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    // Save the current URL in localStorage if the user is not logged in
    if (!isLogin) {

      const url = `${(import.meta.env.VITE_MODE === "local")
          ? setting.development.client_url
          : setting.production.client_url
        }${location.pathname}`;


      if (url.includes("/EmailSearch/findAndVerify/file") ||
        url.includes("/EmailSearch/find/file") ||
        url.includes("/file/verify/")) 
        {

        localStorage.setItem("redirectUrl", location.pathname);
      } else {
        console.log("The URL does not contain the specified paths");
      }


    }
  }, [isLogin, location.pathname]);

  if (isLogin) {


    if (allowedRoles.includes(isLogin["role"])) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + isLogin.token;
      axios.defaults.headers.common['userid'] = isLogin["userId"];
      return (
        <>
          {children}
        </>
      );
    }
    else {
      return <Navigate to="/unauthorized" state={{ from: location }} />
    }
  } else {
    return (
      <Navigate to="/" replace={true} />
    );
  }
}

export default ProtectedRoutes;
