import axios from 'axios';
import setting from "../../setting.json";

const axiosInstance = axios.create({
  baseURL: (import.meta.env.VITE_MODE === "local") 
  ? setting.development.server_url 
  : setting.production.server_url,   headers: {
    'Content-Type': 'application/json',
  }
});

// Add a response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => {
  
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        if(error.response.data.message == "Token expired"){
         window.location.assign('/session-timeout');
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
