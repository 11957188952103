import React, { useCallback, useState, useMemo } from "react";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { ModuleRegistry } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import setting from "../../setting.json";

ModuleRegistry.registerModules([InfiniteRowModelModule]);


const Table = ({ operation, limit = 10, id,columnDefs ,totalCount}) => {

const [loading,setIsLoading]=useState(false);
  





  const [gridApi, setGridApi] = useState(null);



let onGridReady;
let tableStyle;

if(operation == "EmailVerification"){
  tableStyle="table-wrapper w-[100%] h-[50vh]";
  onGridReady =  totalCount &&  useCallback((params) => {
    fetch(`${(import.meta.env.VITE_MODE === "local") 
      ? setting.development.server_url 
      : setting.production.server_url
    }/api/v1/email/verification/file/getAll/infinite?id=${id}&startRow=${0}&endRow=${10}`)
      .then((resp) => resp.json())
      .then(async({data}) => {

       
        const dataSource = {
          rowCount: undefined,
          getRows: (params) => {

            



            console.log('asking for ' + params.startRow + ' to ' + params.endRow);
            // At this point in your code, you would call the server.
            // To make the demo look real, wait for 500ms before returning

            //0-10
            //10-20
            //20-30
            //30-40

            if(params.startRow>0){
              setTimeout(async () =>{
                // take a slice of the total rows

                //make api call try to get three data [data,totalLength]....
                // if on or after the last page, work out the last row.
                 let resp=await fetch(`${(import.meta.env.VITE_MODE === "local") 
                  ? setting.development.server_url 
                  : setting.production.server_url
                }/api/v1/email/verification/file/getAll/infinite?id=${id}&startRow=${params.startRow}&endRow=${limit}`)

              const {data}=await resp.json();



                let lastRow = -1;
                if (totalCount <= params.endRow) {
                  lastRow = totalCount;
                }
                // call the success callback
                params.successCallback(data, lastRow);
              }, 500);

              
            }

            if(params.startRow == 0){
              let lastRow = -1;
              if (totalCount <= params.endRow) {
                lastRow = totalCount;
              }
              // call the success callback
              params.successCallback(data, lastRow);

            }

          
          },
        };
        params.api.setGridOption('datasource', dataSource);
      });
  }, [limit]);
}


if(operation == "EmailSearch"){
  tableStyle="table-wrapper w-[100%] h-[50vh]";
   onGridReady =  useCallback((params) => {
    fetch(`${(import.meta.env.VITE_MODE === "local") 
      ? setting.development.server_url 
      : setting.production.server_url
    }/api/v1/email/search/find/bulk/infinite?id=${id}&startRow=${0}&endRow=${limit}`)
      .then((resp) => resp.json())
      .then(async({data}) => {
        const dataSource = {
          rowCount: undefined,
          getRows: (params) => {

            // At this point in your code, you would call the server.
            // To make the demo look real, wait for 500ms before returning

            //0-10
            //10-20
            //20-30
            //30-40

            if(params.startRow>0){
              setTimeout(async () =>{
                // take a slice of the total rows

                //make api call try to get three data [data,totalLength]....
                // if on or after the last page, work out the last row.
                 let resp=await fetch(`${(import.meta.env.VITE_MODE === "local") 
                  ? setting.development.server_url 
                  : setting.production.server_url
                }/api/v1/email/search/find/bulk/infinite?id=${id}&startRow=${params.startRow}&endRow=${limit}`)

              const {data}=await resp.json();



                let lastRow = -1;
                if (totalCount <= params.endRow) {
                  lastRow = totalCount;
                }
                // call the success callback
                params.successCallback(data, lastRow);
              }, 500);

              
            }

            if(params.startRow == 0){
              let lastRow = -1;
              if (totalCount <= params.endRow) {
                lastRow = totalCount;
              }
              // call the success callback
              params.successCallback(data, lastRow);

            }

          
          },
        };
        params.api.setGridOption('datasource', dataSource);
      });
  }, [limit]);
}





if(operation == "LogTime"){
  tableStyle="table-wrapper w-[100%] h-[70vh]"
  

  onGridReady =  totalCount &&  useCallback((params) => {
    fetch(`${(import.meta.env.VITE_MODE === "local") 
      ? setting.development.server_url 
      : setting.production.server_url
    }/api/v1/user/get/logtime?id=${id}&startRow=${0}&limit=${limit}`)
      .then((resp) => resp.json())
      .then(async({data}) => {

       
        const dataSource = {
          rowCount: undefined,
          getRows: (params) => {

            let url;

            

            if(params["sortModel"].length>0 ){

              const {sort,colId}=params["sortModel"][0];

             url=`${(import.meta.env.VITE_MODE === "local") 
              ? setting.development.server_url 
              : setting.production.server_url
            }/api/v1/user/get/logtime?id=${id}&startRow=${params.startRow}&limit=${limit}&sortOrder=${sort}&sortByField=${colId}`

            }
            else{
              url=`${(import.meta.env.VITE_MODE === "local") 
                  ? setting.development.server_url 
                  : setting.production.server_url
                }/api/v1/user/get/logtime?id=${id}&startRow=${params.startRow}&limit=${limit}`
            }



          

if(params["sortModel"].length>0 || params.startRow>0 ){

              setTimeout(async () =>{
                setIsLoading(true);
             
                 let resp=await fetch(url)
                 setIsLoading(false);

              const {data}=await resp.json();

                let lastRow = -1;
                if (totalCount <= params.endRow) {
                  lastRow = totalCount;
                }
                // call the success callback
                params.successCallback(data, lastRow);
              }, 500);
            }

              
            

            if(params.startRow == 0){
              let lastRow = -1;
              if (totalCount <= params.endRow) {
                lastRow = totalCount;
              }
              // call the success callback
              params.successCallback(data, lastRow);

            }

          
          },
        };
        params.api.setGridOption('datasource', dataSource);
      });
  }, [limit]);
}

if(operation == "OperationTable"){
  tableStyle="table-wrapper w-[100%] h-[70vh]"

  onGridReady =  totalCount &&  useCallback((params) => {
    fetch(`${(import.meta.env.VITE_MODE === "local") 
      ? setting.development.server_url 
      : setting.production.server_url
    }/api/v1/creditHistory/get/operation?id=${id}&startRow=${0}&limit=${limit}`)
      .then((resp) => resp.json())
      .then(async({data}) => {

        

       
        const dataSource = {
          rowCount: undefined,
          getRows: (params) => {

            let url;

            

            if(params["sortModel"].length>0 ){

              const {sort,colId}=params["sortModel"][0];

             url=`${(import.meta.env.VITE_MODE === "local") 
              ? setting.development.server_url 
              : setting.production.server_url
            }/api/v1/creditHistory/get/operation?id=${id}&startRow=${params.startRow}&limit=${limit}&sortOrder=${sort}&sortByField=${colId}`

            }
            else{
              url=`${(import.meta.env.VITE_MODE === "local") 
                  ? setting.development.server_url 
                  : setting.production.server_url
                }/api/v1/creditHistory/get/operation?id=${id}&startRow=${params.startRow}&limit=${limit}`
            }



          

if(params["sortModel"].length>0 || params.startRow>0 ){

              setTimeout(async () =>{
                setIsLoading(true);
             
                 let resp=await fetch(url)
                 setIsLoading(false);

              const {data}=await resp.json();

                let lastRow = -1;
                if (totalCount <= params.endRow) {
                  lastRow = totalCount;
                }
                // call the success callback
                params.successCallback(data, lastRow);
              }, 500);
            }

              
            

            if(params.startRow == 0){
              let lastRow = -1;
              if (totalCount <= params.endRow) {
                lastRow = totalCount;
              }
              // call the success callback
              params.successCallback(data, lastRow);

            }

          
          },
        };
        params.api.setGridOption('datasource', dataSource);
      });
  }, [limit]);
  

}

if(operation == "SubscriptionTable"){
  tableStyle="table-wrapper w-[100%] h-[70vh]"
  onGridReady =  totalCount &&  useCallback((params) => {
    fetch(`${(import.meta.env.VITE_MODE === "local") 
      ? setting.development.server_url 
      : setting.production.server_url
    }/api/v1/creditHistory/get/subscription?id=${id}&startRow=${0}&limit=${limit}`)
      .then((resp) => resp.json())
      .then(async({data}) => {

        

       
        const dataSource = {
          rowCount: undefined,
          getRows: (params) => {

            let url;

            

            if(params["sortModel"].length>0 ){

              const {sort,colId}=params["sortModel"][0];

             url=`${(import.meta.env.VITE_MODE === "local") 
              ? setting.development.server_url 
              : setting.production.server_url
            }/api/v1/creditHistory/get/subscription?id=${id}&startRow=${params.startRow}&limit=${limit}&sortOrder=${sort}&sortByField=${colId}`

            }
            else{
              url=`${(import.meta.env.VITE_MODE === "local") 
                  ? setting.development.server_url 
                  : setting.production.server_url
                }/api/v1/creditHistory/get/subscription?id=${id}&startRow=${params.startRow}&limit=${limit}`
            }



          

if(params["sortModel"].length>0 || params.startRow>0 ){

              setTimeout(async () =>{
                setIsLoading(true);
             
                 let resp=await fetch(url)
                 setIsLoading(false);

              const {data}=await resp.json();

                let lastRow = -1;
                if (totalCount <= params.endRow) {
                  lastRow = totalCount;
                }
                // call the success callback
                params.successCallback(data, lastRow);
              }, 500);
            }

              
            

            if(params.startRow == 0){
              let lastRow = -1;
              if (totalCount <= params.endRow) {
                lastRow = totalCount;
              }
              // call the success callback
              params.successCallback(data, lastRow);

            }

          
          },
        };
        params.api.setGridOption('datasource', dataSource);
      });
  }, [limit]);




}







  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);


  return (
    <div>


      <div className={tableStyle}>

        <div style={gridStyle} className="ag-theme-quartz py-2"

        >      <AgGridReact
            columnDefs={columnDefs}
            pagination={true}
            paginationPageSize={10}
            loading={loading}

            paginationPageSizeSelector={[5, 10, 20,]}            cacheBlockSize={limit}  // Controls the number of rows to fetch on each scroll
            rowBuffer={0}
            rowModelType={'infinite'}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={1000}
            maxBlocksInCache={10}
            onGridReady={onGridReady}

          />
        </div>
      </div>
    </div>
  );
};

export default Table;


