import React, { Suspense } from 'react';
import ProtectedRoutes from '../../app/ProtectedRoute.jsx';
import SuspenseLoading from "../../pages/SuspenseLoading.jsx";

// Direct imports of components
const ErrorPage = React.lazy(() => import('../../pages/ErrorPage.jsx'));
const UserInfo = React.lazy(() => import('../../components/admin/UserInfo/Pages/UserInfo.jsx'));
const LoginLogsPage = React.lazy(() => import('../../components/admin/UserInfo/Pages/LoginLogs.jsx'));
const CreditHistoryPage = React.lazy(() => import('../../components/admin/UserInfo/Pages/CreditHistoryPage.jsx'));
const UserManagementPage = React.lazy(() => import('../../components/admin/Dashboard/component/RightBar/UserManagement.jsx'));
const AdminDashboard = React.lazy(() => import('../../components/admin/Dashboard/Pages/Dashboard.jsx'));
const BackendAPIAdminLevel = React.lazy(() => import('../../components/admin/Dashboard/component/RightBar/BackendCreditApi.jsx'));
const MasterFileUpload=React.lazy(()=>import("../../components/admin/Dashboard/component/RightBar/MasterTableUpload.jsx"))
const Admin = [
  {
    path: "",
    errorElement: <ErrorPage />,
    element: (
      <ProtectedRoutes allowedRoles={["admin"]}>
        <Suspense fallback={<SuspenseLoading />}>
          <AdminDashboard />
        </Suspense>
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "dashboard", // This will match "/admin" exactly
        element: (
          <ProtectedRoutes allowedRoles={["admin"]}>
            <Suspense fallback={<SuspenseLoading />}>
              <UserManagementPage />
            </Suspense>
          </ProtectedRoutes>
        ),
      },
      {
        path: "dashboard/api", // Use a relative path here, no leading "/"
        element: (
          <Suspense fallback={<SuspenseLoading />}>
            <BackendAPIAdminLevel />
          </Suspense>
        ),
      },
      {
        path: "/admin/dashboard/masterTable/upload",
        element: (
          <ProtectedRoutes allowedRoles={["admin"]}>
            
            <Suspense fallback={<SuspenseLoading />}>
          <MasterFileUpload />
        </Suspense>
          </ProtectedRoutes>
        )
      }
    ]
  },
  {
    path: "userinfo/:id",
    element: (
      <ProtectedRoutes allowedRoles={["admin"]}>
        <Suspense fallback={<SuspenseLoading />}>
          <UserInfo />
        </Suspense>
      </ProtectedRoutes>
    )
  },
  {
    path: "user/logs/:id",
    element: (
      <ProtectedRoutes allowedRoles={["admin"]}>
        <Suspense fallback={<SuspenseLoading />}>
          <LoginLogsPage />
        </Suspense>
      </ProtectedRoutes>
    )
  },
  {
    path: "creditHistory/:id",
    element: (
      <ProtectedRoutes allowedRoles={["admin"]}>
        <Suspense fallback={<SuspenseLoading />}>
          <CreditHistoryPage />
        </Suspense>
      </ProtectedRoutes>
    )
  },
 
];

export default Admin;
